<template>
	<!-- 设备管理 -->
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar title="设备管理" left-text="返回" left-arrow @click-left="goback" />
		</van-sticky>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell-group
				v-for="(item, index) in deviceManageList"
				:key="index"
				style="margin: 1.2rem; text-align: left"
				inset
			>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: green"
					v-show="item.status === 0"
				>
					未使用
				</div>
				<div style="text-align: right; margin: 0.5rem 1rem; color: red" v-show="item.status === 1">
					已使用
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #ffdd00"
					v-show="item.status === 2"
				>
					转借中
				</div>
				<van-cell title="设备编号" :value="item.deviceNum" />
				<!--        <van-cell title="ASP编号" :value="item.sapNum" />-->
				<van-cell title="所属类型" :value="item.categoryName" />
				<van-cell title="所属公司" :value="item.companyName" />
				<!--        <van-cell title="名称" :value="item.brand" />-->
				<van-cell title="是否坏桩" :value="item.isBad === 1 ? '是' : '否'" />
				<van-cell title="拥有人" :value="item.haveUserName" />
				<van-cell title="录入人" :value="item.createUserName" />
				<van-cell title="录入时间" :value="dateFormat(item.createTime)" />
				<van-cell title="使用人" :value="item.outUserName" />
				<van-cell title="使用时间" :value="dateFormat(item.useTime)" />
				<van-cell title="盘点时间" :value="dateFormat(item.inventoryTime)" />
				<van-field input-align="right">
					<template #input>
						<van-button
							v-show="authorization('/home-admin/device/device-manage/inventory')"
							type="primary"
							@click="inventory(item)"
							readonly
							size="small"
							style="margin-right: 1rem"
							>盘点</van-button
						>
						<van-button
							v-show="isDeleteFun(item.createUser, item.status)"
							type="danger"
							@click="deleteDevice(item.id)"
							readonly
							size="small"
							>删除</van-button
						>
					</template>
				</van-field>
			</van-cell-group>
		</van-list>
		<!-- 添加按钮 -->
		<van-button
			v-show="addDevicePower()"
			round
			type="info"
			icon="plus"
			style="position: fixed; bottom: 1rem; left: 1rem; padding: 1.6rem 1.1rem"
			@click="showAdd = true"
		></van-button>
		<!-- 弹出层 -->
		<van-popup v-model="showAdd" position="bottom">
			<van-form @submit="onSubmit">
				<van-field
					v-model="deviceAddParams.deviceNum"
					label="设备编号"
					clearable
					placeholder="请填写设备编号"
				>
					<template #button>
						<van-button type="primary" @click="scanCode()" size="small">扫描录入</van-button>
					</template>
				</van-field>
				<van-field
					v-model="deviceAddParams.count"
					label="设备数量"
					clearable
					placeholder="请录入设备数量"
					v-show="deviceAddParams.deviceNum == null || deviceAddParams.deviceNum == ''"
					:rules="
						deviceAddParams.deviceNum == null || deviceAddParams.deviceNum == ''
							? [{ required: true }]
							: [{ required: false }]
					"
				>
				</van-field>
				<!--        <van-field v-model="deviceAddParams.sapNum" label="ASP编号" placeholder='请填写ASP编号' clearable :rules="[{ required: true}]"/>-->
				<!--        <van-field v-model="deviceAddParams.brand" label="名称" clearable placeholder="请填写名称" :rules="[{required: true}]"/>-->
				<!--        <van-field v-model="deviceAddParams.describe" label="描述"  placeholder="请填写描述"/>-->
				<div style="margin: 1rem">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>
		<van-popup v-model="showInventory" position="bottom">
			<van-form @submit="inventorySubmit">
				<van-field
					v-model="deviceInventoryParams.categoryName"
					label="充电桩类型"
					input-align="right"
					clearable
					readonly
				></van-field>
				<van-field
					v-model="deviceInventoryParams.companyName"
					label="所属公司"
					input-align="right"
					clearable
					readonly
					@click="
						authorization('/home-admin/device/device-manage/inventory-deviceid')
							? (showCompanyDrown = true)
							: (showCompanyDrown = false)
					"
				></van-field>
				<van-field
					v-model="deviceInventoryParams.deviceNum"
					label="充电桩编号"
					input-align="right"
					clearable
					:readonly="!authorization('/home-admin/device/device-manage/inventory-deviceid')"
				></van-field>
				<van-field input-align="right" name="isBad" label="是否坏桩">
					<template #input>
						<van-radio-group v-model="deviceInventoryParams.isBad" direction="horizontal">
							<van-radio :name="1">是</van-radio>
							<van-radio :name="0">否</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field input-align="right" name="imageUploader" label="充电桩图片">
					<template #input>
						<van-uploader
							:multiple="true"
							:max-count="40"
							v-model="imageUploader"
							:before-read="imageUploadOuther"
							:before-delete="deleteImage"
							:name="1"
						/>
					</template>
				</van-field>
				<van-field
					v-model="deviceInventoryParams.describe"
					label="备注"
					input-align="right"
					placeholder="请填写备注"
				/>
				<div style="margin: 1rem">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>

		<van-popup v-model="showCompanyDrown" position="bottom">
			<van-picker
				title="所属公司"
				show-toolbar
				value-key="companyManager"
				:columns="companyDrownList"
				@confirm="onCompanySelect"
				@cancel="showCompanyDrown = false"
			/>
		</van-popup>
	</div>
</template>
<script>
	import moment from 'moment';
	import { Toast, Dialog } from 'vant';
	import { authorization } from '../authorization/authorization';
	import ImageCompressor from 'compressorjs';
	export default {
		data() {
			return {
				showLoading: false,
				showAdd: this.$route.query.showAdd === undefined ? false : this.$route.query.showAdd,
				showInventory: false,
				showCompanyDrown: false,
				loading: false,
				finished: false,
				companyDrownList: [],
				deviceManageList: [],
				deviceAddParams: {
					deviceNum: this.$route.query.deviceNum,
					categoryId: this.$route.query.categoryId,
					sapNum: '',
					brand: '',
					describe: '',
					count: '',
				},
				deviceInventoryParams: {},
				imageUploader: [],
				deviceCategory: null,
				params: {
					categoryId: this.$route.query.categoryId,
					haveUser: this.$route.query.userId,
					size: 10,
					page: 1,
				},
				scan: '',
			};
		},
		/*beforeRouteEnter(to, from, next) {
    scanText = from.params.scanText
    if(scanText !== "" && scanText !== undefined){
      showAdd =true
    }else {
      showAdd =false
    }
    next(); // 必需调用
  },*/
		created() {
			this.queryCategoryList();
			this.queryDeviceList();
			this.getCompanyDrown();
		},
		methods: {
			authorization,
			getCompanyDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/company-manager/select-company-drown',
				}).then((res) => {
					this.companyDrownList = res.data.data;
				});
			},
			onCompanySelect(value) {
				//所属公司确认
				this.deviceInventoryParams.companyId = value.companyId;
				this.deviceInventoryParams.companyName = value.companyManager;
				this.showCompanyDrown = false;
			},
			addDevicePower() {
				console.log(this.deviceCategory);
				return (
					this.deviceCategory != null &&
					((authorization('/home-admin/device/device-manage/h5/addLimitStock') &&
						this.deviceCategory.limitStock === 1) ||
						(authorization('/home-admin/device/device-manage/h5/addUnLimitStock') &&
							this.deviceCategory.limitStock === 2))
				);
			},
			isDeleteFun(createUser, status) {
				let userId = localStorage.getItem('userId');
				if (
					createUser === Number(userId) &&
					status === 0 &&
					authorization('/home-admin/device/device-manage/del')
				) {
					return true;
				} else {
					return false;
				}
			},
			goback() {
				this.$router.push('/MyDevice');
			},
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					this.params.page = this.params.page + 1;
					this.queryDeviceList();
				}, 1000);
			},
			queryCategoryList() {
				let param = {
					id: this.params.categoryId,
				};
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
					params: param,
				}).then((res) => {
					if (res.data.data.list.length >= 1) {
						this.deviceCategory = res.data.data.list[0];
					} else {
						this.deviceCategory = null;
					}
				});
			},
			queryDeviceList() {
				this.axios({
					method: 'get',
					url: '/v1/h5/device-manage/list-device-manage',
					params: this.params,
				}).then((res) => {
					if (this.params.page === 1) {
						this.deviceManageList = [];
					}
					for (let i = 0; i < res.data.data.list.length; i++) {
						this.deviceManageList.push(res.data.data.list[i]);
					}
					if (res.data.data.list.length <= 0) {
						this.finished = true;
					}
					// 加载状态结束
					this.loading = false;
				});
			},
			dateFormat(time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			onSubmit() {
				if (this.deviceAddParams.deviceNum != null && this.deviceAddParams.deviceNum != '') {
					this.deviceAddParams.count = 1;
				}

				this.axios({
					method: 'post',
					url: '/v1/h5/device-manage/add-device-manage',
					data: this.deviceAddParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showAdd = false;
						this.deviceManageList = [];
						this.params.page = 1;
						this.queryDeviceList();
						Toast.success('添加成功');
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			inventory(data) {
				this.deviceInventoryParams = { ...data };
				this.imageUploader = [];
				this.handlePreviewImage(this.deviceInventoryParams.imageUrl, 1);
				this.showInventory = true;
			},
			inventorySubmit() {
				this.axios({
					method: 'post',
					url: '/v1/web/device-manage/update-device-manage/' + this.deviceInventoryParams.id,
					data: this.deviceInventoryParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showInventory = false;
						this.deviceManageList = [];
						this.params.page = 1;
						this.queryDeviceList();
						Toast.success('盘点成功');
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			scanCode() {
				this.$router.push({ path: '/QCode', query: { isOpenScan: true } });
			},
			deleteDevice(id) {
				Dialog.confirm({
					title: '删除充电桩',
					message: '确认删除吗？',
				})
					.then(() => {
						this.axios({
							method: 'delete',
							url: '/v1/h5/device-manage/delete-device-manage/' + id,
						}).then((res) => {
							if (res.data.code === 200) {
								this.deviceManageList = [];
								this.params.page = 1;
								this.queryDeviceList();
								Toast.success('删除成功');
							}
						});
					})
					.catch(() => {});
			},
			imageUploadOuther(file, detail) {
				if (file.length === undefined) {
					this.imageUpload(file, detail);
				} else {
					if (this.imageUploader.length + file.length >= 40) {
						Toast.fail('最多只能上传40张照片');
						return;
					}
					for (let i = 0; i < file.length; i++) {
						this.imageUpload(file[i], detail);
					}
				}
			},
			imageUpload(file, detail) {
				this.clickUpload(detail.name, file);
			},
			/**
			 * 压缩图片
			 * @param file
			 * @returns {Promise<unknown>}
			 */
			compressFile(file, quality) {
				return new Promise((resolve, reject) => {
					new ImageCompressor(file, {
						quality: quality, //压缩质量
						checkOrientation: false, //图片翻转，默认为false
						success(result) {
							resolve(result);
						},
						error(e) {
							reject(e);
						},
					});
				});
			},
			deleteImage(file, detail) {
				switch (detail.name) {
					case 1:
						this.imageUploader.splice(detail.index, 1);
						this.deviceInventoryParams.imageUrl = this.getImageUrlArray(this.imageUploader);
						break;
				}
			},
			async clickUpload(type, file) {
				this.showLoading = true;
				let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
				if (file.size > maxSize) {
					file = await this.compressFile(file, 0.2);
				}
				this.uploadImage(file, type);
			},
			uploadImage(file, type) {
				let param = new FormData(); //创建form对象
				param.append('files', file);
				param.append('orderNum', this.deviceInventoryParams.id);
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/image-upload',
					data: param,
				})
					.then((res) => {
						let imgUrl = res.data.data.imageUrl;
						this.handlePreviewImage(imgUrl, type);
						this.flagUpload = true;
						this.showLoading = false;
					})
					.catch(() => {
						Toast.fail('上传失败');
						this.showLoading = false;
					});
			},
			handlePreviewImage(imgUrl, type) {
				if (imgUrl === undefined || imgUrl === null || imgUrl === 'deleteImage' || imgUrl == '') {
					return;
				}
				//默认图片
				let imageObj = {};
				imageObj.url = this.transformUrl(imgUrl);
				let imageArray;
				switch (type) {
					case 1:
						imageArray = imgUrl.split(',');
						if (imageArray.length > 1) {
							for (let i = 0; i < imageArray.length; i++) {
								let image = {};
								image.url = this.transformUrl(imageArray[i]);
								this.imageUploader.push(image);
							}
						} else {
							this.imageUploader.push(imageObj);
						}
						this.deviceInventoryParams.imageUrl = this.getImageUrlArray(this.imageUploader);
						break;
				}
			},
			transformUrl(url) {
				return '/api/v1/web/order-info/preview-image?path=' + url;
			},
			getImageUrlArray(imageObjs) {
				let resultStr = '';
				for (let i = 0; i < imageObjs.length; i++) {
					if (resultStr !== '') {
						resultStr = resultStr + ',';
					}
					resultStr = resultStr + imageObjs[i].url.substring(imageObjs[i].url.indexOf('=') + 1);
				}
				return resultStr;
			},
		},
	};
</script>

<style></style>
